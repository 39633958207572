<template>
  <header class="layout__header">
    <div class="pdf-header">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="handleBackClick" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>
      <span class="text-black">{{ title || $translate('pdf-header_title') }}</span>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import headerMixin from "@/mixins/header.js";
import { backNavigateQueue } from "@/helpers/app";

export default {
  name: "PdfHeader",
  props: ['backToName', 'prev_form_data'],
  mixins: [ headerMixin ],
  computed: {
    ...mapState("pdf", [
      'title',
    ]),
  },
  methods: {
    handleBackClick(){
      if (this.prev_form_data){
        this.$router.replace({ 
          name: 'NavigationItemFormPage', 
          params: {
            menu_id: this.prev_form_data.menu_id,
            form_name: this.prev_form_data.form_name,
            prev_forms: {
              default: this.prev_form_data.default,
              fullscreen: this.prev_form_data.fullscreen,
            },
          }
        });
      } else {
        this.$router.replace({ name: 'MainPage' });
      }
    }
  },
  mounted(){
    backNavigateQueue.set(this.handleBackClick);
  }
}
</script>
<style lang="scss">
.pdf-header{
  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  // align-content: center;
  white-space: nowrap;
  padding: 6px 0 10px;
  overflow: hidden;
  svg{
    position: absolute;
    flex-shrink: 0;
    // left: 0;
  }
  span{
    flex: 1;
    text-align: center;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0 36px;
    user-select: none;
  }
}
</style>