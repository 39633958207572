<template>
  <main class="layout__body-pdf">
    <v-snackbar
      centered
      style="z-index: 17;"
      :timeout="-1"
      :value="loadingStatus"
      class="loading-status"
    >
      <div class="w-full text-center">
        <v-progress-circular
          class="mx-auto"
          indeterminate
        />
        <p>{{ loadingStatus }}</p>
      </div>
    </v-snackbar>
    <div class="viewer-container">
      <!-- <div id="viewer" class="pdfViewer"> -->
        <div class="viewer-content">
          <canvas 
            ref="theCanvas"
            class="viewer-canvas"
          >
          </canvas>
        </div>
      <!-- </div> -->
    </div>
    <!-- <div id="errorWrapper" hidden="true">
      <div id="errorMessageLeft">
        <span id="errorMessage"></span>
        <button id="errorShowMore">
          More Information
        </button>
        <button id="errorShowLess">
          Less Information
        </button>
      </div>
      <div id="errorMessageRight">
        <button id="errorClose">
          Close
        </button>
      </div>
      <div class="clearBoth"></div>
      <textarea id="errorMoreInfo" hidden="true" readonly="readonly"></textarea>
    </div> -->

    <footer class="pdf-footer">
      <div class="pdf-footer__content">
        <div class="pdf-footer__content__left">
          <v-btn
            @click="handleZoomInClick"
            :elevation="0"
            icon
            outlined 
            class="mr-2"
          >+</v-btn>
          <v-btn
            @click="handleZoomOutClick"
            :elevation="0" 
            icon
            outlined
            class="mr-2"
          >-</v-btn>
        </div>
        <div class="pdf-footer__content__right">
          <v-btn
            :elevation="0"
            outlined 
            class="mr-4"
            title="Previous Page" 
            @click="handlePagePrevClick"
          >{{ $translate('pdf_prev-page-btn') }}</v-btn>
          <span class="mr-4">{{ ` ${page || 1} / ${pagesTotal || 1} `}}</span>
          <v-btn
            :elevation="0" 
            outlined
            @click="handlePageNextClick"
          >{{ $translate('pdf_next-page-btn') }}</v-btn>
        </div>

        <!-- <input 
          type="number" 
          id="pageNumber" 
          class="toolbarField pageNumber" 
          value="1" 
          size="4" 
          min="1"
        > -->

      </div>
    </footer>
  </main>
</template>

<script>
import baseApi from "@/helpers/baseApi.js";
import axios from "axios";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import bridge from "@/helpers/bridge";
// import { PDFJS as pdfjsViewer } from 'pdfjs-dist/web/pdf_viewer.js';
// import './Viewer.css';
// const USE_ONLY_CSS_ZOOM = true;
// const TEXT_LAYER_MODE = 0; // DISABLE
const DEFAULT_SCALE_VALUE = 0.8;
const MIN_SCALE_VALUE = 0.5;
const MAX_SCALE_VALUE = 1.75;
const SCALE_STEP = 0.1;
const MAX_REQUEST_ATTEMPTS = 10;
// const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
// const CMAP_PACKED = true;
// const DEFAULT_URL = "../../web/compressed.tracemonkey-pldi-09.pdf";
// const DEFAULT_SCALE_DELTA = 1.1;
// const MIN_SCALE = 0.25;
// const MAX_SCALE = 10.0;
// const DEFAULT_SCALE_VALUE = "auto";

export default {
  name: 'PdfPage',
  props: ['src', 'path'],
  data(){
    return {
      currentScale: DEFAULT_SCALE_VALUE,
      pdfLoadingTask: null,
      pdfDocument: null,
      pdfViewer: null,
      pdfHistory: null,
      pdfLinkService: null,
      page: 1,
      pagesTotal: undefined,
      eventBus: null,
      title: undefined,
      renderTask: undefined,
      generateInterval: undefined,
      generateAttempts: 0,
      generateRequestCancelToken: undefined,
      loadingStatus: undefined,
    }
  },

  mounted(){
    console.log('DISABLING SCREEN CAPTURE');
    bridge.disableScreenCapture();
    if (this.src){
      this.download(this.src);
    } else if (this.path){
      this.getDocumentOnReady(this.path);
    } else {
      this.$store.dispatch('layout/alert', this.$translate('pdf_no-src-alert'));
    }
  },

  destroyed(){
    console.log('ENABLING SCREEN CAPTURE');
    if (this.generateInterval){
      clearInterval(this.generateInterval);
    }
    bridge.enableScreenCapture();
  },

  methods: {
    
    async download(url, options = {}){
      this.$store.dispatch('pdf/loadingStarted');
      var loadingTask = pdfjsLib.getDocument({
        url,
        ...options,
      });
      loadingTask.onProgress = (progressData) => {
        // console.log('progess:', progressData);
        this.$store.dispatch('pdf/setLoadingProgress', progressData)
      };
      let doc = await loadingTask.promise;
      // console.log('doc', doc)
      this.onDocumentLoad(doc);
    },

    async getDocumentOnReady(path){
      this.loadingStatus = this.$translate('pdf_status_generating');
      this.generateInterval = setInterval(() => this.requestGeneratedFile(path), 3000);
    },

    async requestGeneratedFile(path){
      if (typeof this.generateRequestCancelToken != typeof undefined) {
        this.generateRequestCancelToken.cancel("Operation canceled due to new request.")
      }
      this.generateRequestCancelToken = axios.CancelToken.source()
      try {
        if (this.generateAttempts > MAX_REQUEST_ATTEMPTS-1){
          throw new Error(this.$translate('pdf_too-many-requests'))
        }
        path = path.replace('show_pdf', 'check_pdf');
        console.log('requesting pdf rdy status', path);
        let res = await baseApi(path, {
          method: 'GET',
          cancelToken: this.generateRequestCancelToken.token,
        });
        if (res){
          this.generateAttempts++;
          console.log(`generated response(${this.generateAttempts}):`, res.data);
          if (res.data?.ready !== false){
            clearInterval(this.generateInterval);
            path = path.replace('check_pdf', 'show_pdf')
            this.loadingStatus = '';
            let url = process.env.VUE_APP_API_HOST + path;
            console.log('download url:', url)
            await this.download(url, {
              httpHeaders: { 
                'Authorization': baseApi.defaults.headers['Authorization'],
              },
              // withCredentials: true,
            });
          }
        }
      } catch (e){
        console.error(e);
        this.loadingStatus = '';
        this.$store.dispatch('layout/alert', e);
        clearInterval(this.generateInterval);
      }
    },

    onDocumentLoad(doc) {
      this.$store.dispatch('pdf/loadingFinished');
      this.pdfDocument = doc;
      this.pagesTotal = doc.numPages;
      this.setPage(1);
      // Initial/first page rendering
      // this.renderPage(pageNum);
    },
    handleZoomInClick(){
      let scale = this.currentScale + SCALE_STEP > MAX_SCALE_VALUE
        ? MAX_SCALE_VALUE
        : this.currentScale + SCALE_STEP;
      this.renderPage(this.page, scale)
    },
    handleZoomOutClick(){
      let scale = this.currentScale - SCALE_STEP < MIN_SCALE_VALUE
        ? MAX_SCALE_VALUE
        : this.currentScale - SCALE_STEP;
      this.renderPage(this.page, scale)
    },
    handlePageNextClick(){
      if (this.page < this.pagesTotal){
        this.setPage(this.page+1);
      }
    },

    handlePagePrevClick(){
      if (this.page > 1){
        this.setPage(this.page-1);
      }
    },
    setPage(num){
      this.page = num;
      this.renderPage(num);
    },

    async renderPage(num, scale) {
      this.$store.dispatch('pdf/pageRenderingStart');
      // Using promise to fetch the page
      try {
        this.currentScale = scale || DEFAULT_SCALE_VALUE;
        if (this.renderTask) {
          this.renderTask.cancel();
        }
        let page = await this.pdfDocument.getPage(num);
        let viewport = page.getViewport({ 
          scale: this.currentScale, 
        });
        let canvas = this.$refs['theCanvas'];
        let ctx = canvas.getContext('2d');
        // Support HiDPI-screens.
        let outputScale = window.devicePixelRatio || 1;
        // let outputScale = 1;
        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height =  Math.floor(viewport.height) + "px";

        let transform = outputScale !== 1
          ? [outputScale, 0, 0, outputScale, 0, 0]
          : null;

        // Render PDF page into canvas context
        let renderContext = {
          canvasContext: ctx,
          transform: transform,
          viewport: viewport,
        };
        this.renderTask = page.render(renderContext);
        // page.render(renderContext);
      } catch (err){
        console.log(err);
        this.handleLoadingException(err)
        // console.error(err);
      }
      

        // Wait for rendering to finish
        this.renderTask.promise.then(() => {
          this.$store.dispatch('pdf/pageRenderingEnd');
        //   this.pageRendering = false;
        //   // if (pageNumPending !== null) {
        //     // New page rendering is pending
        //     // this.renderPage(pageNumPending);
        //     // pageNumPending = null;
        //   // }
        });
    },
    // async init(){
      // let container = document.getElementById("theCanvas");
      // let pdfViewer = new PDFViewer({
      //   container: container,
      // });
      // let pdf = await pdfjsLib.getDocument(this.docPath);
      // pdfViewer.setDocument(pdf);
    //   try {
    //     const loadingTask = pdfjs.getDocument(src);
    //     const pdfDocument = await loadingTask.promise;
    //     const firstPage = await pdfDocument.getPage(1);
    //     const canvas = this.$refs.theCanvas;
    //     console.log(canvas)
    //     const context = canvas.getContext("2d");
    //     const scale = 1.0;
    //     // Display page on the existing canvas with 100% scale.
    //     const viewport = firstPage.getViewport({ scale });
    //     // Support HiDPI-screens.
    //     const outputScale = window.devicePixelRatio || 1;

    //     canvas.width = Math.floor(viewport.width * outputScale);
    //     canvas.height = Math.floor(viewport.height * outputScale);
    //     // canvas.style.width = Math.floor(viewport.width) + "px";
    //     // canvas.style.height = Math.floor(viewport.height) + "px";

    //     const transform = outputScale !== 1 
    //       ? [outputScale, 0, 0, outputScale, 0, 0] 
    //       : null;
    //     // return renderTask.promise;
    //     const renderTask = firstPage.render({
    //       canvasContext: context,
    //       transform,
    //       viewport,
    //     });
    //     return renderTask;
    //     } catch (reason){
    //       console.error("Error: " + reason);
    //       this.$store.dispatch('layout/alert', reason);
    //     }
    // },

    // init(){
    //   // const eventBus = new pdfjsViewer.EventBus();
    //   // this.eventBus = eventBus;

    //   // const linkService = new pdfjsViewer.PDFLinkService({
    //   //   eventBus,
    //   // });
    //   // this.pdfLinkService = linkService;

    //   // this.l10n = pdfjsViewer.NullL10n;

    //   // const container = document.getElementById("viewerContainer");
    //   // // const pdfViewer = new pdfjsViewer.PDFViewer({
    //   // //   container,
    //   // //   eventBus,
    //   // //   linkService,
    //   // //   l10n: this.l10n,
    //   // //   useOnlyCssZoom: USE_ONLY_CSS_ZOOM,
    //   // //   textLayerMode: TEXT_LAYER_MODE,
    //   // // });
    //   // // this.pdfViewer = pdfViewer;
    //   // linkService.setViewer(pdfViewer);

    //   // this.pdfHistory = new pdfjsViewer.PDFHistory({
    //   //   eventBus,
    //   //   linkService,
    //   // });
    //   // linkService.setHistory(this.pdfHistory);

    //   // document
    //   //   .getElementById("pageNumber")
    //   //   .addEventListener("change", function () {
    //   //     this.page = this.value | 0;

    //   //     // Ensure that the page number input displays the correct value,
    //   //     // even if the value entered by the user was invalid
    //   //     // (e.g. a floating point number).
    //   //     if (this.value !== this.page.toString()) {
    //   //       this.value = this.page;
    //   //     }
    //   //   });

    //   // eventBus.on("pagesinit", function () {
    //   //   // We can use pdfViewer now, e.g. let's change default scale.
    //   //   pdfViewer.currentScaleValue = DEFAULT_SCALE_VALUE;
    //   // });

    //   // eventBus.on(
    //   //   "pagechanging",
    //   //   function (evt) {
    //   //     const page = evt.pageNumber;
    //   //     const numPages = this.pagesCount;

    //   //     document.getElementById("pageNumber").value = page;
    //   //     document.getElementById("previous").disabled = page <= 1;
    //   //     document.getElementById("next").disabled = page >= numPages;
    //   //   },
    //   //   true
    //   // );
    // },

    // async open(params) {
      // if (this.pdfLoadingTask) {
      //   // We need to destroy already opened document
      //   return this.close().then(
      //     function () {
      //       // ... and repeat the open() call.
      //       return this.open(params);
      //     }.bind(this)
      //   );
      // }
      // const url = src;
      // // const self = this;

      // // this.setTitleUsingUrl(url);

      // // Loading document.
      // const loadingTask = pdfjsLib.getDocument({
      //   url,
      //   maxImageSize: MAX_IMAGE_SIZE,
      //   // cMapUrl: CMAP_URL,
      //   // cMapPacked: CMAP_PACKED,
      // });
      // this.pdfLoadingTask = loadingTask;

      
      
      // try {
      //   let pdfDocument = await loadingTask.promise();
      //   // Document loaded, specifying document for the viewer.
      //   this.pdfDocument = pdfDocument;
      //   this.pdfViewer.setDocument(pdfDocument);
      //   this.pdfLinkService.setDocument(pdfDocument);
      //   // this.pdfHistory.initialize({ fingerprint: pdfDocument.fingerprint });

      //   this.loadingBar.hide();
      //   this.setTitleUsingMetadata(pdfDocument);
      // } catch (exception){
      //   this.handleLoadingException(exception)
      // }
    // },

    async setTitleUsingMetadata(pdfDocument) {
      console.log(pdfDocument)
      // const self = this;
      // pdfDocument.getMetadata().then(function (data) {
      //   const info = data.info;
      //   const metadata = data.metadata;
      //   self.documentInfo = info;
      //   self.metadata = metadata;

      //   // Provides some basic debug information
      //   console.log(
      //     "PDF " +
      //       pdfDocument.fingerprint +
      //       " [" +
      //       info.PDFFormatVersion +
      //       " " +
      //       (info.Producer || "-").trim() +
      //       " / " +
      //       (info.Creator || "-").trim() +
      //       "]" +
      //       " (PDF.js: " +
      //       (pdfjsLib.version || "-") +
      //       ")"
      //   );

      //   let pdfTitle;
      //   if (metadata && metadata.has("dc:title")) {
      //     const title = metadata.get("dc:title");
      //     // Ghostscript sometimes returns 'Untitled', so prevent setting the
      //     // title to 'Untitled.
      //     if (title !== "Untitled") {
      //       pdfTitle = title;
      //     }
      //   }
      //   if (!pdfTitle && info && info.Title) {
      //     pdfTitle = info.Title;
      //   }
      //   if (pdfTitle) {
      //     self.setTitle(pdfTitle + " - " + document.title);
      //   }
      // });
    },

    // zoomIn(ticks){
    //   // let newScale = this.pdfViewer.currentScale;
    //   // do {
    //   //   newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
    //   //   newScale = Math.ceil(newScale * 10) / 10;
    //   //   newScale = Math.min(MAX_SCALE, newScale);
    //   // } while (--ticks && newScale < MAX_SCALE);
    //   // this.pdfViewer.currentScaleValue = newScale;
    // },

    // zoomOut(ticks){
    //   // let newScale = this.pdfViewer.currentScale;
    //   // do {
    //   //   newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
    //   //   newScale = Math.floor(newScale * 10) / 10;
    //   //   newScale = Math.max(MIN_SCALE, newScale);
    //   // } while (--ticks && newScale > MIN_SCALE);
    //   // this.pdfViewer.currentScaleValue = newScale;
    // },

    // close() {
    // const errorWrapper = document.getElementById("errorWrapper");
    // errorWrapper.hidden = true;

    // if (!this.pdfLoadingTask) {
    //   return Promise.resolve();
    // }

    // const promise = this.pdfLoadingTask.destroy();
    // this.pdfLoadingTask = null;

    // if (this.pdfDocument) {
    //   this.pdfDocument = null;

    //   this.pdfViewer.setDocument(null);
    //   this.pdfLinkService.setDocument(null, null);

    //   if (this.pdfHistory) {
    //     this.pdfHistory.reset();
    //   }
    // }

    // return promise;
    // },
    handleLoadingException(exception){
      const message = exception && exception.message;
      const l10n = this.l10n;
      let loadingErrorMessage;

      if (exception instanceof pdfjsLib.InvalidPDFException) {
        // change error message also for other builds
        loadingErrorMessage = l10n.get(
          "invalid_file_error",
          null,
          "Invalid or corrupted PDF file."
        );
      } else if (exception instanceof pdfjsLib.MissingPDFException) {
        // special message for missing PDFs
        loadingErrorMessage = l10n.get(
          "missing_file_error",
          null,
          "Missing PDF file."
        );
      } else if (exception instanceof pdfjsLib.UnexpectedResponseException) {
        loadingErrorMessage = l10n.get(
          "unexpected_response_error",
          null,
          "Unexpected server response."
        );
      } else {
        loadingErrorMessage = l10n.get(
          "loading_error",
          null,
          "An error occurred while loading the PDF."
        );
      }

      loadingErrorMessage.then(function (msg) {
        this.error(msg, { message });
      });
      self.loadingBar.hide();
    }
  },
  // increaseScale(steps = 1) {
  //   let newScale = this._currentScale;
  //   do {
  //     newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
  //     newScale = Math.ceil(newScale * 10) / 10;
  //     newScale = Math.min(MAX_SCALE, newScale);
  //   } while (--steps > 0 && newScale < MAX_SCALE);
  //   this.currentScaleValue = newScale;
  // }
}
</script>
<style lang="scss">
.layout__body-pdf{
  // background-color: #F6F6F6;
  // padding: 8px 24px;
  // overflow: auto;
  flex-basis: auto;
  flex-grow: 1;
  background: #aaaaaa;
}
.viewer-container{
  width: 100%;
  // min-height: 100%;
  height: auto;
}
.viewer-content{
  min-height: 100%;
  overflow: auto;
  padding: 8px;
  // background: #aaaaaa;
}
.viewer-canvas{
  margin: 0 auto;
}
.pdf-footer{
  padding-bottom: 48px;
  @supports(padding: max(0px)) {      // check if ios device 
      padding-bottom: calc(48px + env(safe-area-inset-bottom, 0px));
    }
  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 8px 16px;
    background-color: #FFF;
    &__left {
      flex: 1;
    }
    &__right {
      margin-left: auto;
      
    }
  }
}

// header {
//   background-color: rgba(244, 244, 244, 1);
// }

// header h1 {
//   border-bottom: 1px solid rgba(216, 216, 216, 1);
//   color: rgba(133, 133, 133, 1);
//   font-size: 23px;
//   font-style: italic;
//   font-weight: normal;
//   overflow: hidden;
//   padding: 10px;
//   text-align: center;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

// body {
//   // background: url(images/document_bg.png);
//   color: rgba(255, 255, 255, 1);
//   font-family: sans-serif;
//   font-size: 10px;
//   height: 100%;
//   width: 100%;
//   overflow: hidden;
//   padding-bottom: 5rem;
// }

// section {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   font-size: 2rem;
// }

// footer {
//   // background-image: url(images/toolbar_background.png);
//   height: 4rem;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
//   box-shadow: 0 -0.2rem 0.5rem rgba(50, 50, 50, 0.75);
// }

// .toolbarButton {
//   display: block;
//   padding: 0;
//   margin: 0;
//   border-width: 0;
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-color: rgba(0, 0, 0, 0);
// }

// .toolbarButton.pageUp {
//   position: absolute;
//   width: 18%;
//   height: 100%;
//   left: 0;
//   // background-image: url(images/icon_previous_page.png);
//   background-size: 2rem;
// }

// .toolbarButton.pageDown {
//   position: absolute;
//   width: 18%;
//   height: 100%;
//   left: 18%;
//   // background-image: url(images/icon_next_page.png);
//   background-size: 2rem;
// }

// #pageNumber {
//   -moz-appearance: textfield; /* hides the spinner in moz */
//   position: absolute;
//   width: 28%;
//   height: 100%;
//   left: 36%;
//   text-align: center;
//   border: 0;
//   background-color: rgba(0, 0, 0, 0);
//   font-size: 1.2rem;
//   color: rgba(255, 255, 255, 1);
//   // background-image: url(images/div_line_left.png),
//     // url(images/div_line_right.png);
//   background-repeat: no-repeat;
//   background-position: left, right;
//   background-size: 0.2rem, 0.2rem;
// }

// .toolbarButton.zoomOut {
//   position: absolute;
//   width: 18%;
//   height: 100%;
//   left: 64%;
//   // background-image: url(images/icon_zoom_out.png);
//   background-size: 2.4rem;
// }

// .toolbarButton.zoomIn {
//   position: absolute;
//   width: 18%;
//   height: 100%;
//   left: 82%;
//   // background-image: url(images/icon_zoom_in.png);
//   background-size: 2.4rem;
// }

// .toolbarButton[disabled] {
//   opacity: 0.3;
// }

// .hidden {
//   display: none;
// }
// [hidden] {
//   display: none !important;
// }

// #viewerContainer {
//   position: absolute;
//   overflow: auto;
//   width: 100%;
//   top: 5rem;
//   bottom: 4rem;
//   left: 0;
//   right: 0;
// }

// canvas {
//   margin: auto;
//   display: block;
// }

// .pdfViewer .page .loadingIcon {
//   width: 2.9rem;
//   height: 2.9rem;
//   // background: url("images/spinner.png") no-repeat left top / 38rem;
//   border: medium none;
//   animation: 1s steps(10, end) 0s normal none infinite moveDefault;
//   display: block;
//   position: absolute;
//   top: calc((100% - 2.9rem) / 2);
//   left: calc((100% - 2.9rem) / 2);
// }

// @keyframes moveDefault {
//   from {
//     background-position: 0 top;
//   }

//   to {
//     background-position: -39rem top;
//   }
// }

// #loadingBar {
//   position: relative;
//   height: 0.6rem;
//   background-color: rgba(51, 51, 51, 1);
//   border-bottom: 1px solid rgba(51, 51, 51, 1);
//   margin-top: 5rem;
// }

// #loadingBar .progress {
//   position: absolute;
//   left: 0;
//   width: 0;
//   height: 100%;
//   background-color: rgba(221, 221, 221, 1);
//   overflow: hidden;
//   transition: width 200ms;
// }

// @keyframes progressIndeterminate {
//   0% {
//     left: 0;
//   }
//   50% {
//     left: 100%;
//   }
//   100% {
//     left: 100%;
//   }
// }

// #loadingBar .progress.indeterminate {
//   background-color: rgba(153, 153, 153, 1);
//   transition: none;
// }

// #loadingBar .indeterminate .glimmer {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 5rem;
//   background-image: linear-gradient(
//     to right,
//     rgba(153, 153, 153, 1) 0%,
//     rgba(255, 255, 255, 1) 50%,
//     rgba(153, 153, 153, 1) 100%
//   );
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   animation: progressIndeterminate 2s linear infinite;
// }

// #errorWrapper {
//   background: none repeat scroll 0 0 rgba(255, 85, 85, 1);
//   color: rgba(255, 255, 255, 1);
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 3.2rem;
//   z-index: 1000;
//   padding: 0.3rem;
//   font-size: 0.8em;
// }

// #errorMessageLeft {
//   float: left;
// }

// #errorMessageRight {
//   float: right;
// }

// #errorMoreInfo {
//   background-color: rgba(255, 255, 255, 1);
//   color: rgba(0, 0, 0, 1);
//   padding: 0.3rem;
//   margin: 0.3rem;
//   width: 98%;
// }
</style>